/* eslint-disable func-style */
"use server";

export const GetAnalyticsVars = async (): Promise<{
  analyticsUrl: string | undefined;
  analyticsApiKey: string | undefined;
  analyticsEnabled: boolean;
}> => {
  "use server";

  return {
    analyticsUrl: process.env.ANALYTICS_URL || undefined,
    analyticsApiKey: process.env.ANALTYICS_API_KEY || undefined,
    analyticsEnabled: process.env.ANALYTICS_ENABLED === "true",
  };
};
